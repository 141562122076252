import React from "react"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import ContactFrom from "./ContactFrom"
import whatsappicon from "./../../Images/whatsapp.png"

const Contact = () => {
  return (
    <>
      <HeadTitle />
      <ContactFrom />
      <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
      <img src={whatsappicon} className="fixed-icon"/>
              </a>
      
    </>
  )
}

export default Contact
