import React from "react"
import Card from "./Card"
import Tdata from "./Tdata"
import "./Testimonial.css"

const AllItem = () => {
  return (
    <>
      <section className='Testimonial mtop'>
       <div className="heading-text"> <h1 className="hea">what  people say</h1>
        <p>Discoverd what our satisfied customers have to say <br/>about their experiances with our services</p></div>
        <div className='card-test'>
         
          <Card/>
      
        </div>
      </section>
    </>
  )
}

export default AllItem
