import popular1 from "../../../Images/popular1.jpg";
import popular2 from "../../../Images/popular2.jpg";
import popular3 from "../../../Images/popular3.jpg";
import popular4 from "../../../Images/popular4.jpg";
import popular5 from "../../../Images/popular5.jpg";

const PopularData = [
  {
    id: 1,
    image: popular1,
    country: "Tonk Phatak, Jaipur",
    name: "Narayana Girls PG",
  },
  {
    id: 2,
    image: popular2,
    country: "280, near gopalpura mode",
    name: "Narayana Luxury Hostel",
  },
  {
    id: 3,
    image: popular3,
    country: " A59, Jai Ambey Nagar, Milap Nagar",
    name: "Narayana Hostel Group",
  },
  {
    id: 4,
    image: popular4,
    country: "18, lions colony, Tonk Rd",
    name: "Narayana Sitabari",
  },
  {
    id: 5,
    image: popular5,
    country: "D-9, Satya Vihar, Indra Puri, Vidhayak Nagar, Lalkothi",
    name: "Narayana Luxury Lalkothi",
  },
];

export default PopularData;
