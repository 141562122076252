import React from "react";
import "./Services.css";
import whatsappicon from "./../../Images/whatsapp.png"
const Services = () => {
  // const services = [
  //   {
  //     title: "Comfortable Accommodations",
  //     description:
  //       "Choose from private rooms or shared dorms, all with comfy beds, fresh linens, and ample storage space.",
  //     icon: "bed",
  //   },
  //   {
  //     title: "High-Speed Wi-Fi",
  //     description:
  //       "Stay connected with friends and family, or catch up on work with our unlimited high-speed Wi-Fi throughout the hostel.",
  //     icon: "wifi",
  //   },
  //   {
  //     title: "Shared Kitchen & Lounge",
  //     description:
  //       "Meet fellow travelers, cook up delicious meals, or relax in our cozy lounge area with games and TV.",
  //     icon: "tv",
  //   },
  //   {
  //     title: "Fun Activities & Events",
  //     description:
  //       "Join our weekly pub crawls, movie nights, game nights, and more! We have something for everyone.",
  //     icon: "calendar",
  //   },
  //   {
  //     title: "24/7 Security & Support",
  //     description:
  //       "Rest assured knowing our friendly staff is available 24/7 to assist you with any questions or concerns.",
  //     icon: "lock",
  //   },
  // ];

  return (
    <div className="services">
      <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
      <img src={whatsappicon} className="fixed-icon"/>
              </a>
      <section className="c-section">
        <h2 className="c-section__title">
          <span>Our Services</span>
        </h2>
        <ul className="c-services">
          <li className="c-services__item">
            <h3>High-Speed Wi-Fi</h3>
            <p>
              Stay connected with friends and family, or catch up on work with
              our unlimited high-speed Wi-Fi throughout the hostel.
            </p>
          </li>
          <li className="c-services__item">
            <h3>Fun Activities & Events</h3>
            <p>
              Join our weekly pub crawls, movie nights, game nights, and more!
              We have something for everyone.
            </p>
          </li>
          <li className="c-services__item">
            <h3>Shared Kitchen & Lounge</h3>
            <p>
              Meet fellow travelers, cook up delicious meals, or relax in our
              cozy lounge area with games and TV.
            </p>
          </li>
          <li className="c-services__item">
            <h3>24/7 Security & Support</h3>
            <p>
              Rest assured knowing our friendly staff is available 24/7 to
              assist you with any questions or concerns.
            </p>
          </li>
          <li className="c-services__item">
            <h3>Comfortable Accommodations</h3>
            <p>
              Choose from private rooms or shared dorms, all with comfy beds,
              fresh linens, and ample storage space.{" "}
            </p>
          </li>
        </ul>
      </section>
     
    </div>
  );
};

// const Service = ({ title, description, icon }) => (
//   <div className="service">
//     <i className={`fa fa-${icon}`}></i>
//     <h3>{title}</h3>
//     <p>{description}</p>
//   </div>
// );

export default Services;
