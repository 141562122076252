import gallery1 from "../../../Images/gallery/gallery-1.png";
import gallery2 from "../../../Images/gallery/gallery-2.png";
import gallery3 from "../../../Images/gallery/gallery-3.png";
import gallery4 from "../../../Images/gallery/gallery-4.png";
import gallery5 from "../../../Images/gallery/gallery-5.png";



const data = [
  {
    cover: gallery1,
  },
  {
    cover: gallery2,
  },
  {
    cover: gallery3,
  },
  {
    cover: gallery4,
  },
  {
    cover: gallery5,
  },
  {
    cover: gallery1,
  },
  {
    cover: gallery3,
  },
]

export default data
