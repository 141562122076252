import React, { useState } from 'react';
import './PrivacyPolicy.css'; // Import your CSS file

const PrivacyPolicy = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // Manage accordion state

  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Privacy Policy</h1>
      <div className="privacy-policy-content">
        {/* Introduction section */}
        <div className="privacy-policy-section">
          <h2 className="privacy-policy-section-title">
            <button
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            >
              {isAccordionOpen ? 'Collapse' : 'Expand'} Introduction
            </button>
          </h2>
          {isAccordionOpen && (
            <p>
              <strong>Narayana House Hostel ("we," "us," or "our")</strong> is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information from users of our website and hostel services.
            </p>
            
          )}
        </div>

        {/* Additional sections */}
        <div className="privacy-policy-section">
          <h2 className="privacy-policy-section-title">
            <button
              onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            >
              {isAccordionOpen ? 'Collapse' : 'Expand'} Information We Collect
            </button>
          </h2>
          {isAccordionOpen && (
            <p>
              {/* Provide a detailed explanation of the types of information you collect */}
            </p>
          )}
        </div>

       
      </div>
    </div>
  );
};

export default PrivacyPolicy;
