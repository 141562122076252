import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [organizationData, setOganizationData] = useState({});
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    const getoRganization = async () => {
      const apiUrl = `${process.env.REACT_APP_ADMIN_URL}/sitesettings/organization`;
      try {
        const response = await axios.get(apiUrl);
        if (response) {
          setOganizationData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getoRganization();
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="container flex_space">
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
          <Link to="/" onClick={closeMobileMenu} className="narayaana-logo">
            <img
              className="nh"
              src={organizationData.logo_url}
              height={"70px"}
              style={{ borderRadius: "50%" }}
            />
          </Link>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <Link to="/" onClick={closeMobileMenu} className="fw-bold">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={closeMobileMenu}>
                About us
              </Link>
            </li>
            <li>
              <Link to="/gallery" onClick={closeMobileMenu}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/destinations" onClick={closeMobileMenu}>
                Hostels
              </Link>
            </li>
            <li>
              <Link to="/testimonial" onClick={closeMobileMenu}>
                Testimonial
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={closeMobileMenu}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={closeMobileMenu}>
                Services
              </Link>
            </li>
            <li>
              <a href="https://student.narayanahouse.com/login">
                <button className="log">Login / Register</button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
