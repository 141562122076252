import gallery1 from "../../Images/gallery-1.jpg"
import gallery2 from "../../Images/gallery-2.jpg"
import gallery3 from "../../Images/gallery-3.jpg"
import gallery4 from "../../Images/gallery-4.jpg"
import gallery5 from "../../Images/gallery-5.jpg"
import gallery6 from "../../Images/gallery-6.jpg"




const GalleryData = [
  {
    img: gallery1,
    title: "Gallery One",
  },
  {
    img: gallery2,
    title: "Gallery Two",
  },
  {
    img: gallery3,
    title: "Gallery Three",
  },
  {
    img: gallery4,
    title: "Gallery Four",
  },
  {
    img: gallery5,
    title: "Gallery Five",
  },
  {
    img: gallery6,
    title: "Gallery Six",
  },
]

export default GalleryData
