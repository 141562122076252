import React from "react"
import "./download.css"

const Download = () => {
  return (
    <>
      
    </>
  )
}

export default Download
