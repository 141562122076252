import React from "react";
import "./About.css";
import AboutCard from "./AboutCard";
import HeadTitle from "../../Common/HeadTitle/HeadTitle";
import featureImg1 from "../../Images/feature-img-1.jpg";
import whatsappicon from "./../../Images/whatsapp.png";

const About = () => {
  return (
    <>
      <HeadTitle />

      <section className="about top">
        <div className="container">
          <AboutCard />
        </div>
      </section>

      <section className="container">
        <div className="image">
          <img src={featureImg1} alt="Feature" className="feature-image" />
        </div>
        <h1>
          Our <span className="highlight">Features</span>
        </h1>
        <h2>
          What makes <span className="highlight">Narayana House</span>{" "}
          exceptional?
        </h2>
        <p>
          What sets Narayana House apart are all the value-adds we provide to
          support our residents' success and uplift their stay. Our hostels
          integrate premium facilities with authentic service and community
          building.
        </p>
        <div className="features">
          <div className="feature">
            <h3>Caring Service</h3>
            <p>
              Our friendly staff nurtures each guest like family. We get to know
              your needs, lend a listening ear, and provide advice to uplift
              your path ahead.
            </p>
          </div>
          <div className="feature">
            <h3>Strategic Locations</h3>
            <p>
              Stay right next to top educational institutes, career centers, and
              transit hubs for easy commuting as you achieve your goals.
            </p>
          </div>
          <div className="feature">
            <h3>Community Living</h3>
            <p>
              Forge friendships, celebrate milestones together, and find
              motivation by surrounding yourself with like-minded aspirants.
            </p>
          </div>
          <div className="feature">
            <h3>Purpose-Built Amenities</h3>
            <p>
              Our state-of-the-art hostels feature amenities tailored
              specifically for our resident demographics – from high-speed WiFi
              to recreation lounges.
            </p>
          </div>
          <div className="feature">
            <h3>Peaceful Spaces</h3>
            <p>
              Unwind in our beautiful courtyards, balconies, and terrace
              retreats landscaped specifically for rejuvenation amidst your busy
              schedule.
            </p>
          </div>
        </div>
        <p>
          We welcome you to experience the Narayana difference first-hand
          through our signature hospitality, strategic locations, connections
          made, and state-of-the-art amenities designed expressly to uplift your
          purpose-driven stay. Discover your home away from home with us.
        </p>
        <div className="button-container">
          <button className="btn">
            Explore More <i className="arrow right"></i>
          </button>
        </div>
      </section>
      <a
        href="https://wa.me/919829249215"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappicon} className="fixed-icon" />
      </a>
    </>
  );
};

export default About;
