import React, { useEffect, useState } from "react";
import axios from "axios";
// import StarRating from "./starRating.js";

const Card = () => {
  const [user, setUser] = useState([]);
  const loadUsers = async () => {
    try {
      const response = await axios.get("https://narayanahouse-admin-api.affx.cloud/sitesettings");
      setUser(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    loadUsers();
  }, []);
  
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<i key={i} className="fa fa-star yellow-star"></i>);
    }
    return stars;
  };
  
  
  return (
    <>
      {user.map((obj, index) => {
        const { name, comment, bio, image_url, star_rating } = obj; // Assuming the rating is included in your data
        return (
          <div className="box" key={index}>
            <div className="details flex">
              <div className="img">
                <img src={image_url} alt="" />
              </div>
              <div className="name">
              
                <h3>{name}</h3>
                <p>{bio}</p>
                <div className="stars">
                  <span style={{fontSize:"15px"}}>{star_rating} </span>{renderStars(star_rating)}
                </div>
              </div>
            </div>
            <div className="para">
              <i className="fa fa-quote-left"></i>
              <p>{comment}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;
