import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className='container grid'>
          <div className='box'>
            <h2>ABOUT US</h2>
            <p>Narayana House Established 2012</p>
            <br />
            <p>
              Supportive and empowering hostel communities blending premium
              facilities with authentic service for resident success. Feel at
              home on your pathway to achievement.
            </p>
            <div className='icon flex_space'>
              <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-whatsapp'></i>
              </a>
              <i className='fab fa-facebook-f'></i>
              <i className='fab fa-twitter'></i>
              <i className='fab fa-linkedin'></i>
              <i className='fab fa-instagram'></i>
              <i className='fab fa-pinterest'></i>
              <i className='fab fa-youtube'></i>
            </div>
          </div>

          <div className='box'>
            <h2>NAVIGATION</h2>
            <ul className="list-menu">
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/about'>About us</Link>
              </li>
              <li>
                <Link to='/gallery'>Gallery</Link>
              </li>
              <li>
                <Link to='/destinations'>Destinations</Link>
              </li>

              <li>
                <Link to='/testimonial'>Testimonial</Link>
              </li>
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className='box'>
            <h2>NEWSLETTER</h2>
            <p>Add your mail to subscribe to our NEWSLETTER</p>

            <input type='text' name='' id='' />
            <input type='text' className='primary-btn' value='SUBSCRIBE' />
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>
          © 2024 All Rights Reserved. Developed by{" "}
          <a target='_blank' href='https://affworld.in' rel='noopener noreferrer'>
            Affworld Technologies
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
