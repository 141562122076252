import work1 from "../../../Images/works/work-1.png";
import work2 from "../../../Images/works/work-2.png";
import work3 from "../../../Images/works/work-3.png";




const Wdata = [
  {
    id: 1,
    cover: work1,
    title: "Search Multiple Destinations",
    desc: "Discover your ideal Narayana House from our range of heritage locations across Jaipur based on preferences.",
  },
  {
    id: 2,
    cover: work2,
    title: "Talk To Our Managers",
    desc: "Experienced managers help determine the perfect Narayana House stay aligned to your priorities.",
  },
  {
    id: 3,
    cover: work3,
    title: "Select Best Hostel For You",
    desc: "Choose a supportive Narayana House community with amenities to match your goals and uplift your success.",
  },
]
export default Wdata
