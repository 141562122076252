import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sdata from "../Components/Destinations/Sdata";
import EmptyFile from "../Common/Empty/EmptyFile";
import { useParams } from "react-router-dom";
import "./singlepage.css";
import ScrolloingCard from "../Common/ScrollingCard/ScrolloingCard";
import axios from "axios";

const SinglePage = () => {
  const { title } = useParams();
  const [item, setItem] = useState(null);
  const [data, setData] = useState({
    full_name: "",
    contact_no: "",
    email: "",
    hostel_location: "",
    message: "",
  });
    const URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    let item = Sdata.find((item) => item.title === title);
    if (item) {
      setItem(item);
    }
  }, [title]);

  const handelChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
const handelSubmit = async ()=>{
    const url = `${URL}/api/quotes/`;

     console.log("payload---", data);
     try {
       const config = {
         headers: {
           "Content-type": "application/json",
         },
       };
       const res = await axios.post(url, data, config);
       if (res?.status === 201) {
         alert(res?.data?.message);
       }
     } catch (error) {
       console.log(error.message);
     }
  setData({
    full_name:"",
    contact_no:"",
    hostel_location:"",
    message:"",
    email:""
  })
}

  return (
    <>
      {item ? (
        <>
          <div className="contanier">
            <img src={item.image} alt="img" className="fix-img" />
          </div>
          <div className="heading-div">
            <div className="sub-div">
              <Link to="/destinations">
                {" "}
                <button className="btn">Go Back</button>
              </Link>
            </div>
            <div className="sub-div text-heading">{item.title}</div>
            <div className="sub-div">
              <button className="btn">Contact</button>
            </div>
          </div>
          <div className="single-page">
            <article>
              <div class="cards-wrapper">
                <div class="card">
                  {" "}
                  <ScrolloingCard
                    title={item.title}
                    discription={item.desc}
                    image={item.paraImage_one}
                  />
                </div>
                <div class="card">
                  {" "}
                  <ScrolloingCard
                    title={item.title}
                    discription={item.desc}
                    image={item.paraImage_one}
                  />
                </div>
                <div class="card">
                  {" "}
                  <ScrolloingCard
                    title={item.title}
                    discription={item.desc}
                    image={item.paraImage_one}
                  />
                </div>
                <div class="card">
                  {" "}
                  <ScrolloingCard
                    title={item.title}
                    discription={item.desc}
                    image={item.paraImage_one}
                  />
                </div>
                <div class="card">
                  {" "}
                  <ScrolloingCard
                    title={item.title}
                    discription={item.desc}
                    image={item.paraImage_one}
                  />
                </div>
              </div>
            </article>
            <div className="flex-div">
              <div className="overview-main-contanier">
                <div className="overview-comtanier">
                  <h1>Overview</h1>
                  {item.sidepara}{" "}
                </div>
                <div>
                  <div className="overview-comtanier">
                    <h1>Details</h1>
                    <ul>
                      <li>Location : {item.location}</li>
                      <li>contact : {item.contact}</li>
                    </ul>
                  </div>
                </div>
                <div className="overview-comtanier">
                  <h1>Reviews</h1>
                  <ui>
                    {item?.reviews.map((obj) => {
                      return (
                        <>
                          <li>
                            {obj.rating} ⭐{obj.text}
                          </li>
                        </>
                      );
                    })}
                  </ui>
                </div>
                <div className="overview-comtanier">
                  <h1> Location</h1>
                  <iframe
                    src={item.gmap}
                    width={"100%"}
                    height={"300px"}
                  ></iframe>
                </div>
                <div className="overview-comtanier">
                  <h1> Features</h1>
                  {item.features}
                </div>
              </div>
              <form className="form-controler">
                <h1 style={{ marginBottom: "10px" }}>Enquiry Now</h1>
                <p>
                  If you have any query related to this listing, please share
                  your details with us so that we can assist you
                </p>
                <input
                  type="text"
                  className="input-text"
                  placeholder="Enter Full Name"
                  name="full_name"
                  onChange={handelChange}
                />
                <input
                  type="text"
                  className="input-text"
                  placeholder="Contact"
                  name="contact_no"
                  onChange={handelChange}
                />
                <input
                  type="text"
                  className="input-text"
                  placeholder="Email Address"
                  name="email"
                  onChange={handelChange}
                />
                <input
                  type="text"
                  className="input-text"
                  placeholder="Hostel Location "
                  name="hostel_location"
                  onChange={handelChange}
                />
                <label for="message-box">Message</label>
                <textarea
                  type="text"
                  className="message-box"
                  id="message-box"
                  required
                  name="message"
                  onChange={handelChange}
                />
                <button type="button" className="btn" onClick={handelSubmit}>
                  {" "}
                  Enquiry Now
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <EmptyFile />
      )}
    </>
  );
};

export default SinglePage;
