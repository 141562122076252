import popular1 from "../../Images/popular1.jpg";
import popular2 from "../../Images/popular2.jpg";
import popular3 from "../../Images/popular3.jpg";
import popular4 from "../../Images/popular4.jpg";
import popular5 from "../../Images/popular5.jpg";

const Sdata = [
  {
    id: 1,
    title: "Narayana Girls PG",
    image:
      "https://hostalperegrino.es/wp-content/uploads/2019/06/IMG_2345-corregida2.jpg",
    desc: "Premium amenities and caring hospitality for female students and professionals right on bustling Tonk Road across from civil service exam coaching since 2012.",
    sidepara:
      "Enjoy our nurturing home close to coaching institutes with stylish rooms, nutritious meals, communal spaces and rooftop terrace access.",
    paraImage_one:
      "https://hostalperegrino.es/wp-content/uploads/2019/06/IMG_2345-corregida2.jpg",
    paraImage_two:
      "https://hostalperegrino.es/wp-content/uploads/2019/06/IMG_2345-corregida2.jpg",
    location: "Plot No. 2 Joshi Colony, Tonk Road",
    contact: "+91 7891097785",
    reviews: [
      {
        rating: 5,
        text: "Amazing facilities, food, location!",
      },
      {
        rating: 4.5,
        text: "Caring staff, felt like home.",
      },
    ],
    features:
      "3 Tiers Security Common Areas Fire Escape Staircase Indoor Games  Luxurious Entrance Lobby with Visitors Lounge Multipurpose Hall er Backup in All Flats",
    gmap: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3501.9849841733167!2d77.30011757495666!3d28.63021213418701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sPlot%20No.%202%20Joshi%20Colony%2C%20Tonk%20Road!5e0!3m2!1sen!2sin!4v1709107514787!5m2!1sen!2sin",
  },
  {
    id: 2,
    title: "Narayana Luxury Hostel",
    image: popular2,
    desc: "Upgrade room and community amenities for girls from our Himmat Nagar hostel with caring service since 2015. Close proximity to top institutes.",
    sidepara:
      "Stay in premium furnished rooms with access to recreation lounge, outdoor green spaces, backup power and celebrate your milestones with us.",
    paraImage_one: popular2,
    paraImage_two: popular2,
    location: "280 Gopalpura Mode, Himmat Nagar",
    contact: "+91 9783842715",
    reviews: [
      {
        rating: 4.8,
        text: "Upgraded amenities, modern hostel.",
      },
      {
        rating: 4.7,
        text: "Luxury facilities, helpful staff.",
      },
    ],
    features:
      "3 Tiers Security Common Areas Fire Escape Staircase Indoor Games  Luxurious Entrance Lobby with Visitors Lounge Multipurpose Hall er Backup in All Flats",
    gmap: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d28472.263137914844!2d75.75960904967164!3d26.870696039240805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s280%20Gopalpura%20Mode%2C%20Himmat%20Nagar!5e0!3m2!1sen!2sin!4v1709107669808!5m2!1sen!2sin",
  },
  {
    id: 3,
    title: "Narayana Hostel Group",
    image: popular3,
    desc: "Our group of hostels near Drishti IAS/RAS since 2017 nurtures female civil services aspirants through mentoring, facilities and exam prep support.",
    sidepara:
      "Find your community of excellence here with spacious rooms, high-speed WiFi, 1-on-1 guidance, celebration of achievements and more.",
    paraImage_one: popular3,
    paraImage_two: popular3,
    location: "A59 Jai Ambey Nagar, Near Drishti IAS/RAS",
    contact: "+91 9829249215",
    reviews: [
      {
        rating: 5,
        text: "Helped me qualify for IAS!",
      },
      {
        rating: 4.8,
        text: "Supportive environment.",
      },
    ],
    features:
      "3 Tiers Security Common Areas Fire Escape Staircase Indoor Games  Luxurious Entrance Lobby with Visitors Lounge Multipurpose Hall er Backup in All Flats",
    gmap: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.436103810176!2d75.79498887489314!3d26.857882162445566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db52ec0b9975b%3A0x11c235228fd2ff70!2sNarayana%3A%20A%20group%20of%20Girls%20hostels%20%2F%20PG%20Accommodation%20Near%20Drishti%20IAS%2FRAS!5e0!3m2!1sen!2sin!4v1709107845558!5m2!1sen!2sin",
  },
  {
    id: 4,
    title: "Narayana Sitabari",
    image: popular4,
    desc: "Established 2018 behind leading coaching institutes, our Sitabari hostel delivers academic support and caring hospitality with convenience.",
    sidepara:
      "Stay minutes from your classes and enjoy nutritious meals, attentive staff 24/7, recreation activities and celebrate your progress with us.",
    paraImage_one: popular4,
    paraImage_two: popular4,
    location: "Lions Colony, Tonk Road",
    contact: "+91 9414953030",
    reviews: [
      {
        rating: 4.9,
        text: "Ideal location, amazing staff!",
      },
      {
        rating: 4.5,
        text: "Helped my exam preparation.",
      },
    ],
    features:
      "3 Tiers Security Common Areas Fire Escape Staircase Indoor Games  Luxurious Entrance Lobby with Visitors Lounge Multipurpose Hall er Backup in All Flats",
      gmap:"https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d56962.05627534964!2d75.75730547812289!3d26.835864372256896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sTonk%20Rd!5e0!3m2!1sen!2sin!4v1709107909939!5m2!1sen!2sin"
  },
  {
    id: 5,
    title: "Narayana Luxury Lalkothi",
    image: popular5,
    desc: "Our 2021 luxury hostel in Lalkothi blends premium facilities like hotel-style room furnishings, recreation lounge and more along with welcoming service.",
    sidepara:
      "Just 10 mins from the airport/station, use us as your base to explore Jaipur and make global connections in our communal hub.",
    paraImage_one: popular5,
    paraImage_two: popular5,
    location: "D-9 Satya Vihar, Lalkothi",
    contact: "+91 7891097785",
    reviews: [
      {
        rating: 4.7,
        text: "Luxury facilities, felt like hotel stay.",
      },
      {
        rating: 4.5,
        text: "Made friends from around the world.",
      },
    ],
    features:
      "3 Tiers Security Common Areas Fire Escape Staircase Indoor Games  Luxurious Entrance Lobby with Visitors Lounge Multipurpose Hall er Backup in All Flats",
      gmap:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.489676036915!2d75.7960806748942!3d26.887950261155236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db42450e0d1fb%3A0x23ddc793aa55c8c8!2sd%2C%209%2C%20Satya%20Vihar%2C%20Lal%20Kothi%20Scheme%2C%20Lalkothi%2C%20Jaipur%2C%20Rajasthan%20302015!5e0!3m2!1sen!2sin!4v1709107977936!5m2!1sen!2sin"
  },
];

export default Sdata;
