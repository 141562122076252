import React from "react"
import { Link } from "react-router-dom"
import "./Destinations.css"
//import Sdata from "./Sdata"

const Cards = ({ item: { id, image, title, sidepara, location,  desc, paraImage_one, paraImage_two } }) => {
  return (
    <>
      <div className="items-card me-5">
        <div className="imgs">
          <img src={image} alt="Gallery Image" />

          {/* <Link to={`/hostel/${title}`} className="blogItem-link">
            <i className="fas fa-external-link-alt"></i>
          </Link> */}
        </div>
        <div className="align-i">
          <h4>{title}</h4>
          <p>{location}</p>
         <u> <Link to={`/hostel/${title}`} className="blogItem-link">
          <h4 className="view">View</h4>
          </Link></u>
        
        </div>
        
      </div>
    </>
  );
}

export default Cards
