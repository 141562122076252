import React, { useState } from "react";
import "./Contact.css";
import axios from "axios";
import { Link } from "@mui/material";
const URL = process.env.REACT_APP_BACKEND_URL;

const ContactFrom = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [allValue, setAllValue] = useState([]);

  const formSubmit = async (e) => {
    e.preventDefault();

    const newValue = { fname, lname, phone, email, subject, company, message };
    setAllValue([...allValue, newValue]);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        alert("Invalid email format");
        return;
    }
    const url = `${URL}/api/quotes/`;
    const data = {
      full_name: fname,
      contact_no: phone,
      email: email,
      hostel_location: company,
      room_sharing_type: "na",
      message: message,
    };
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const res = await axios.post(url, data, config);
      if (res?.status === 201) {
        alert(res?.data?.message);
        setFname("");
        setLname("");
        setPhone("");
        setEmail("");
        setSubject("");
        setCompany("");
        setMessage("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <section className="contact mtop">
        <div className="container flex ">
          <div className="main-content">
            <h2>Contact From</h2>
            <p>Fill out the form below, we will get back you soon.</p>

            <form onSubmit={formSubmit}>
              <div className="grid1">
                <div className="input">
                  <span>
                    First Name <label>*</label>
                  </span>
                  <input
                    type="text"
                    name="fname"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    required
                    className=""
                  />
                </div>
                <div className="input">
                  <span>
                    Last Name <label>*</label>
                  </span>
                  <input
                    type="text"
                    name="lname"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    required
                  />
                </div>
                <div className="input">
                  <span>
                    Phone Number <label>*</label>
                  </span>
                  <input
                    type="number"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="input">
                  <span>
                    Email <label>*</label>
                  </span>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="input">
                  <span>Subject</span>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="input">
                  <span>Your location </span>
                  <input
                    type="text"
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>
              <div className="input inputlast">
                <span>
                  Write Your Message <label>*</label>
                </span>
                <textarea
                  cols="30"
                  rows="10"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button className="primary-btn">Submit Now</button>
            </form>
          </div>

          <div className="side-content">
            <h3>Visit our Hostels</h3>
            <p>
              Explore our vibrant location, where convenience meets comfort, and
              discover the perfect blend of accessibility and serenity.
            </p>
            <br />

            <h3>Message us</h3>
            <span>narayanahouse@gmail.com</span>
            <br />
            <span>+91 789 109 7785</span>
            <br />

            <div className="icon">
              <h3>Follow Us</h3>

              <div className="flex_space">
              <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-whatsapp'></i>
              </a>
                <a
                  href="https://www.facebook.com/narayanahousePGHostelforgirls/"
                  target="_blank"
                 
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <i className="fab fa-twitter"></i>
                <i className="fab fa-linkedin"></i>
                <i className="fab fa-instagram"></i>
                <i className="fab fa-pinterest"></i>
                <i className="fab fa-youtube"></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="show-data">
        {allValue.map((cureentValue, i) => {
          const { fname, lname, phone, email, subject, company, message } =
            cureentValue;
          return (
            <>
              <div className="sign-box" key={i}>
                <h1>Send Successfully</h1>
                <h3>
                  First Name : <p>{fname}</p>
                </h3>
                <h3>
                  Last Name : <p>{lname}</p>
                </h3>
                <h3>
                  Contact Number : <p>{phone}</p>
                </h3>
                <h3>
                  Email : <p>{email}</p>
                </h3>
                <h3>
                  Subject : <p>{subject}</p>
                </h3>
                <h3>
                  Company Name: <p>{company}</p>
                </h3>
                <h3>
                  Your Message : <p>{message}</p>
                </h3>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
};

export default ContactFrom;
