import React from "react";
import "./About.css";
import aboutImage from "../../Images/about-img-1.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AboutCard = () => {
  return (
    <>
      <div className="aboutCard mtop flex_space">
      <div className="ab-card-container ">
        <h4 className="about-title">About Us</h4>
        <h1>
          Where <span> Comfort Meets Adventure</span>, Your Home Away from Home
        </h1>
        <p>
          Narayana House was founded in 2012 by compassionate doctor couple Dr. Ashish Sharma and Dr. Veena Sharma. What began as a small hostel catering to students preparing for civil services exams has now blossomed into a thriving community that provides affordable accommodations and nurturing support to all guests.
        </p>
        <p>
          Beyond being a residence, Narayana House contributes to underprivileged communities through medical clinics and local initiatives. Despite the pandemic, it remained a COVID-free space, ensuring resident safety. As we expand, our commitment to care, compassion, and community remains. Narayana House continues its mission of supporting every resident's journey to success.
        </p>
        <Link to="/destinations">
          <button className="secondary-btn">
            Explore More <i className="fas fa-long-arrow-alt-right"></i>
          </button>
        </Link>
      </div>
      <div className="row row2 about-image">
        <img
          src={aboutImage}
          alt="aboutImage"
          className="image"
        />
      </div>
    </div>
    </>
  );
};

export default AboutCard;
