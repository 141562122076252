import React, { useState, useEffect } from "react";
import Data from "./Data";
import axios from "axios";
const Home = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;
  const [name, setname] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [HostelLocation, setHostelLocation] = useState("");
  const [roomType, setRoomType] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const URL = process.env.REACT_APP_BACKEND_URL;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const handleSubmit = async () => {
    const url = `${URL}/api/quotes/`;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Invalid email format");
      return;
    }
    if (!HostelLocation) {
      alert("please select hostel location");
    }
    if (!contact) {
      alert("please enter contact number");
    }
    const data = {
      full_name: name,
      contact_no: contact,
      email: email,
      hostel_location: HostelLocation,
      room_sharing_type: roomType,
      message: Message,
    };
    console.log("payload---", data);
    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const res = await axios.post(url, data, config);
      if (res?.status === 201) {
        alert(res?.data?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    setContact("");
    setname("");
    setEmail("");
    setHostelLocation("");
    setRoomType("");
    setMessage("");
  };

  return (
    <>
      <section className="slider">
        <div className="control-btn">
          <button className="prev" onClick={prevSlide}>
            <i className="fas fa-caret-left"></i>
          </button>
          <button className="next" onClick={nextSlide}>
            <i className="fas fa-caret-right"></i>
          </button>
        </div>

        {Data.map((slide, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && <img src={slide.image} alt="Home Image" />}
            </div>
          );
        })}
      </section>

      <div className="slide-form">
        <div style={{ display: "flex" }}>
          <div className="firstdiv">
            <h2>Narayana House</h2>
            <span style={{ color: "gray" }}>Enquiry</span>

            <form>
              <div className="flex_space">
                <input
                  type="text"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className="flex_space">
                <select
                  value={HostelLocation}
                  onChange={(e) => setHostelLocation(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select Hostel
                  </option>
                  <option value="Narayana Girls PG - Plot No. 2 Joshi Colony, Tonk Road">
                    Narayana Girls PG - Plot No. 2 Joshi Colony, Tonk Road
                  </option>
                  <option value="Narayana Luxury Hostel - 280 Gopalpura Mode, Himmat Nagar">
                    Narayana Luxury Hostel - 280 Gopalpura Mode, Himmat Nagar
                  </option>
                  <option value="Narayana Hostel Group - A59 Jai Ambey Nagar, Near Drishti IAS/RAS">
                    Narayana Hostel Group - A59 Jai Ambey Nagar, Near Drishti
                    IAS/RAS
                  </option>
                  <option value="Narayana Sitabari - Lions Colony, Tonk Road">
                    Narayana Sitabari - Lions Colony, Tonk Road
                  </option>
                  <option value="Narayana Luxury Lalkothi - D-9 Satya Vihar, Lalkothi">
                    Narayana Luxury Lalkothi - D-9 Satya Vihar, Lalkothi
                  </option>
                </select>
                <input
                  type="number"
                  placeholder="Contact"
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                />
              </div>
              <div className="flex_space">
                <div className="flex_space">
                  <textarea
                    placeholder="Message"
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="messagee-box"
                  />
                </div>
              </div>
              <div className="flex_space">
                <input
                  type="button"
                  value="SUBMIT"
                  className="submit"
                  onClick={handleSubmit}
                />
              </div>
            </form>
          </div>
          <p style={{ fontSize: "32px", fontWeight: "700" }} className="ortext">
            OR
          </p>
          <div style={{ marginTop: "80px" }} className="dis">
            <p
              style={{ fontSize: "22px", fontWeight: "700" }}
              className="text-adjust"
            >
              Go Back to Your Dashboard
            </p>
            <a href="https://student.narayanahouse.com/login">
              <button type="button" className="btnlogin">
                LOGIN
              </button>
            </a>

            <p
              style={{ fontSize: "22px", fontWeight: "700", marginTop: "60px" }}
              className="text-adjust"
            >
              Don’t Have Account yet ?
            </p>
            <a href="https://student.narayanahouse.com/register">
              <button
                type="button"
                className="btnlogin"
                style={{ backgroundColor: "white", color: "#384D6C" }}
              >
                REGISTER
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
