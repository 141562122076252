import React from "react"
import "../../App.css"
import Hero from "../HomeSection/Hero"
import HomeAbout from "../HomeSection/HomeAbout"
import MostPopular from "../HomeSection/popular/MostPopular"
import DestinationHome from "../HomeSection/Destina/DestinationHome"
import Download from "../HomeSection/Download/Download"
import Works from "../HomeSection/Works/Works"
import Gallery from "../HomeSection/gallery/Gallery"
import whatsappicon from "./../../Images/whatsapp.png"

const Home = () => {
  return (
    <>
      <div className="homesec">
     
      <Hero />
      <HomeAbout />
      <MostPopular />
      <DestinationHome />
      <Download />
      <Works />
      <Gallery />
      
      <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
      <img src={whatsappicon} className="fixed-icon"/>
              </a>
      </div>
    </>
  )
}

export default Home
