import React from "react"
import HeadTitle from "../../Common/HeadTitle/HeadTitle"
import AllItem from "./AllItem"
import whatsappicon from "./../../Images/whatsapp.png"

const Destinations = () => {
  return (
    <div>
      <HeadTitle />
      <AllItem />
      <a href='https://wa.me/919829249215' target='_blank' rel='noopener noreferrer'>
      <img src={whatsappicon} className="fixed-icon"/>
              </a>
    </div>
  )
}

export default Destinations
