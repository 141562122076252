import axios from "axios";

const Data = [];

const fetchImages = async () => {
  const apiUrl = `${process.env.REACT_APP_ADMIN_URL}/sitesettings/images`;
  try {
    const response = await axios.get(apiUrl);
    const images = response.data;
    images.forEach((imageObject, index) => {
      Data.push({ image: imageObject.url });
    });
  } catch (error) {
    console.log(error);
  }
};

fetchImages();

export default Data;
